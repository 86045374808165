.title-banner {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 6rem;
}

@media (max-width: 600px) {
  .title-banner {
    font-size: 4rem;
  }
}
