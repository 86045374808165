.card-panel {
  font-family: "IBM Plex Sans", sans-serif;
  align-self: center;
  width: 60vw;
  position: relative;
  z-index: 10;
  margin-top: -7px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  border-radius: 8px;
  background: #f6f8fa;
  padding: 20px;
}
@media (max-width: 1200px) {
  .card-panel {
    width: 80vw;
  }
}
@media (max-width: 768px) {
  .card-panel {
    width: 80vw;
    margin: auto;
  }
}

@media (max-width: 576px) {
  .card-panel {
    width: 90vw;
  }
}

.card-panel-title,
.card-panel-span {
  color: #33418e;
}

.card-panel-title {
  font-size: 2rem;
  font-weight: bold;
}

.card-panel-span {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 2rem;
  /* width: 750px; */
}

.card-panel-p {
  font-size: 1.4rem;
  color: #414141;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.card-panel-button {
  width: 150px;
  color: #33418e;
  font-size: 30px;
  border: none;
  background: transparent;
  padding-left: 0px;
  margin-left: -5px;
}
