.text-secondary {
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-subtitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
.multiline-ellipsis {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  line-height: 1.4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-white-img {
  max-width: 100%;
  border-radius: '10px';
}
@media (min-width: 568px) {
  .card-white-img {
    max-width: 100%;
    border-radius: '10px';
    width: 500px;
    height: 500px;
  }
}
