.navbar-light {
  background: #33418e 0% 0% no-repeat padding-box;
}

.flex-wrapperA {
  width: 100%;
}

.iconLang {
  row-gap: 13px;
}

@media (max-width: 1200px) {
  .iconLang {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 195px;
  }
}
.font-style-normal {
  font-style: normal !important;
}
.font-weight-normal {
  font-weight: normal !important;
}
.tuiasi-nav {
  max-height: 120px;
}

@media (max-width: 1199px) {
  .navbar-menu {
    position: absolute;
    top: 98px;
    background: rgb(56, 59, 140);
  }
}

.tuiasi-nav .nav-item {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-links {
  margin-left: 100px;
}

.search {
  display: inline-flex;
  align-items: center;
  color: black;
  border-radius: 40px;
  transition: 0.3s;
}

.search :is(input, button) {
  color: black;
  border: none;
  background: transparent;
  outline: none;
}

.search__input {
  width: 0;
  transition: width 0.5s;
}

.search__button {
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: color 0.25s;
  padding: 0 !important;
}

.search__button:hover {
  color: #e3e3e3;
}

.search:focus-within {
  background-color: white;
  color: black;
}

.search:focus-within svg {
  color: black !important;
}

.search:focus-within input {
  width: auto;
}

.search:focus-within input {
  width: 160px;
  color: black;
  transition: 0.3s;
}

::placeholder {
  font: inherit;
  color: black;
}
.language-options {
  position: absolute;
  /* max-height: 0; */
  overflow: hidden;
  transition: 0.3s;
  padding: 0;
  left: 0;
  bottom: -100%;
}
.language-options.show {
  display: block;
  position: absolute;
  max-height: fit-content;
  transition: 0.3s;
  left: 100%;
  bottom: 100%;
}
@media (min-width: 1500px) {
  .language-options.show {
    bottom: -200%;
    left: -100%;
  }
}

nav {
  height: 6rem;
  width: 100vw;
  background-color: #33418e;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  position: fixed;
  z-index: 10;
}

/*Styling logo*/
.logo {
  padding: 1vh 1vw;
  text-align: center;
  display: flex;
  align-items: center;
  height: 6rem;
  width: 14rem;
}

/*Styling Links*/
.nav-links {
  display: flex;
  list-style: none;
  width: 88vw;
  padding: 0 0.7vw;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
}
.nav-links li a {
  text-decoration: none;
  margin: 0 0.7vw;
}
.nav-links li a:hover {
  color: #61dafb;
}
.nav-links li {
  position: relative;
}

/*Styling Buttons*/
.login-button {
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  margin-left: 2vw;
  font-size: 1rem;
  cursor: pointer;
}
.login-button:hover {
  color: #131418;
  background-color: #f2f5f7;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}
.join-button {
  color: #131418;
  background-color: #61dafb;
  border: 1.5px solid #61dafb;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  cursor: pointer;
}
.join-button:hover {
  color: #f2f5f7;
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div {
  width: 30px;
  height: 3px;
  background: #f2f5f7;
  margin: 5px;
  transition: all 0.3s ease;
}
.hamburger {
  display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 1500px) {
  nav {
    position: fixed;
    z-index: 3;
  }
  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
  }
  .nav-links {
    position: fixed;
    background: #33418e;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 1s ease-out;
    pointer-events: none;
  }
  .nav-links.open {
    pointer-events: all;
  }
  /* @media only screen and (max-height: 768px) {
    .nav-links.open {
      clip-path: circle(1000px at 90% -10%);
      -webkit-clip-path: circle(1000px at 90% -10%);
    }
  }

  @media only screen and (min-height: 769px) and (max-height: 1024px) {
    .nav-links.open {
      clip-path: circle(2000px at 90% -10%);
      -webkit-clip-path: circle(2000px at 90% -10%);
    }
  } */

  .nav-links li {
    opacity: 1 !important;
  }
  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
  }
  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }
  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }
  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.7s;
  }
  .nav-links li:nth-child(5) {
    transition: all 0.5s ease 0.8s;
  }
  .nav-links li:nth-child(6) {
    transition: all 0.5s ease 0.9s;
    margin: 0;
  }
  .nav-links li:nth-child(7) {
    transition: all 0.5s ease 1s;
    margin: 0;
  }
  li.fade {
    opacity: 1;
  }
}
/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
