.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.pagination .page-item {
  list-style: none;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
}

.pagination .page-item.active {
  color: #33418e;
  font-weight: bold;
}

.pagination .page-item.disabled {
  color: #ddd;
}

.pagination .page-link {
  display: block;
  width: 100%;
  height: 100%;
}
