@media (min-width: 992px) {
  .ml-lg-n7 {
    margin-left: -7rem;
  }
}
.blur-container-hero {
  background: rgba(51, 65, 142, 0.3);
}

@media (min-width: 991px) {
  .project-section-image {
    width: 500px;
  }
}

@media (max-width: 768px) {
  .project-section-image {
    width: 90vw;
  }
}

@media (max-width: 576px) {
  .project-section-image {
    width: 80vw;
  }
}
