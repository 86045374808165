.button-arrow {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #33418e;
  font-size: 1.5rem;
  border: none;
  background: transparent;
}

.button-arrow img {
  margin-left: 10px;
}

.icon-arrow {
  filter: invert(0%);
}

@media (min-width: 768px) {
  .md-align-self-center {
    align-self: center !important;
  }
}
