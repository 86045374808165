.text-number {
  color: #414141;
  font-size: 20px;
  margin-bottom: -10px;
  padding-bottom: 0px;
  padding: 0px;
}

.span-digit {
  margin-top: 0px;
  color: #022a4a;
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 1023px) {
  .span-digit {
    font-size: 50px;
  }
}

@media (min-width: 1024px) {
  .span-digit {
    font-size: 100px;
  }
}

.span-plus {
  font-size: px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 50px;
  margin-bottom: 20px;
}

.container-number {
  font-family: 'IBM Plex Sans', sans-serif;
}
