#input-seacrh-bar-big::placeholder {
  color: #0027ff;
}

.searchBar-btn {
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  background: #4d66f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 40px 80px #4d66f9a3;
  color: #fff;
}

.max-width-90{
  max-width: 90%;
}
