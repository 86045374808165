.hero-project {
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-position: 0px 30%;
}

.hero-project h1,
.hero-project span {
  color: #e4eef8;
  /* font-size: 4rem; */
  margin-left: 10rem;
}

@media screen and (max-width: 768px) {
  .hero-project h1,
  .hero-project span {
    font-size: 3rem;
    margin-left: 5rem;
  }
}

@media screen and (max-width: 480px) {
  .hero-project h1,
  .hero-project span {
    font-size: 2rem;
    margin-left: 2rem;
  }
}

.blur-container-hero {
  width: 100%;
  height: 155px;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  transition: width 0.3s;
  display: flex;
  align-items: center;
}

.hero-project-text {
  /* width: 35%; */
  margin-left: 10rem;
  /* margin-bottom: -9rem; */
  z-index: 1;
}
