#wrapper-numbers {
  border-bottom: 1px solid #707070;
  margin-bottom: 50px;
  padding-bottom: 69px;
}

#cardPanel {
  height: 692px;
}

.image-glass {
  position: absolute;
  right: 0;
}

.section-circle {
  margin-top: 20px;
}
@media (min-width: 576px) {
  .section-circle {
    margin-top: 100px;
  }
}
.section-circle h2 {
  margin-bottom: 50px;
  text-align: center;
}

@media (min-width: 911px) {
  .section-circle h2 {
    text-align: end;
  }
}
.article-card-bubble {
  align-self: center;
  padding: 12px;
}

.article-card-bubble p {
  color: #414141;
  font-size: 20px;
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2px;
}
