.hero-project {
  height: 640px;
  background-repeat: no-repeat;
  background-size: cover;
}


.articles-title {
  margin-left: 210px;
  font-size: 2rem;
  font-weight: bold;
  color: #33418e;
}
