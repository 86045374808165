.hr-line {
  width: 90%;
  margin: 0 auto;
}

.hr-line hr {
  border: 0;
  height: 2px;
  background: #333;
}
