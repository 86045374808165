.hero-container {
  height: 640px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.titleHero {
  font-family: "IBM Plex Sans", sans-serif;
}

@media screen and (max-width: 768px) {
  .titleHero {
    font-size: 60px;
  }
}

@media screen and (max-width: 480px) {
  .titleHero {
    font-size: 40px;
  }
}

.hero-content {
  align-self: self-end;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 3rem;
  backdrop-filter: blur(50px);
}

@media (min-width: 992px) {
  .hero-content {
    align-self: var(--align-self, end);
  }
}
@media screen and (max-width: 480px) {
  .hero-content {
    /* height: calc(100% - 80px); */
    align-self: flex-end;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.hero-text {
  color: #ffffff;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0px;
}
