.articles-section {
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 3rem;
}

.description {
  padding: 50px 25px;
  /* width: 1000px; */
}

.description-label {
  padding-left: 3rem;
  color: #33418e;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #33418e;
  font-weight: bold;
  font-size: 2rem;
}
