.bubbles-image {
  width: 300px;
}

@media (min-width: 576px) {
  .bubbles-image {
    max-width: unset !important;
    width: 500px;
  }
}

@media (min-width: 991px) {
  .bubbles-image {
    max-width: unset !important;
    width: 700px;
  }
}
