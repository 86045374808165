#btn-custom {
  background: #4d66f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 40px 80px #4d66f973;
  border-radius: 53px;
  opacity: 1;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: normal;
  font-size: 23px;
  font-weight: 400;
  /* padding: 19px 78px 23px 58px; */
  padding: 19px 30px 23px 50px;
  max-height: 74px;
  transition: 0.3s !important;
}

#btn-custom:hover {
  box-shadow: 0px 42px 80px #4d67f9b4;
}

#btn-custom img {
  transition: 0.3s !important;
}
#btn-custom:hover img {
  transition: 0.3s !important;
  margin-left: 3rem;
}
