.hero-library {
  background: transparent url('/src/assets/images/big-data-sun.jpg') 0% 0%
    no-repeat padding-box;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  padding-bottom: 5rem;
}

.hero-content-library {
  width: 65%;
}

.hero-library-subtitle {
  color: #fff;
  font-size: 1.8rem;
}

.hero-library-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-left: 2rem;
  align-self: center;
  margin-bottom: 5rem;
}

@media screen and (min-width: 768px) {
  .hero-library-container {
    padding-left: 10rem;
  }
}

.search-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
