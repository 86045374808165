.img-contact {
  width: 100%;
  border-radius: 10px;
}

.container-contact-section {
  width: 90%;
}

.content-contact {
  border-right: 1px solid #707070;
}

.icons-wrapper {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-left: 10px;
}
.icons-wrapper img {
  width: 25px;
}
.border-left {
  border-left: 1px solid #707070;
}

.labels {
  margin-left: 10px;
}
