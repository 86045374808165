/* .image-gallery img {
  width: 300px;
  border-radius: 10px;
} */

.image-gallery {
  max-width: 1700px;
  border: 1px solid red;
}

.blur-container {
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  transition: width 0.3s;
  position: relative;
  color: #fff;
  z-index: 100;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  transition: 0.3s;
}

.blur-container h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.blur-container.expanded {
  height: 160px;
}

.blur-container.normal {
  height: 85px;
}

.blur-container p {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.container-button-arrow {
  /* padding-top: 1rem; */
}

.profile-picture {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}
