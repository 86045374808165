.title-doc {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  color: #414141;
}

.data-doc {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
  color: #414141;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 0.6rem;
}
