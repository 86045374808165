.contact-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-section {
  margin-bottom: 20px;
}

.contact-section h3 {
  color: #333;
}

.contact-section p {
  color: #666;
  font-size: 16px;
}

.contact-section a {
  color: #007bff; /* Blue color for links */
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
}
